<template>
  <div class="home">
    <header id="myHeader"></header>
  
  <section class="statistics">
    <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/shouye-diejia.png" style="width: 1000px;" />
  </section>


  <!--解决方案-->
  <div class="hs"> 
  <h2>解决方案</h2>
</div>
<el-tabs v-model="activeName" @tab-click="handleClick" style="width: 1000px; font-weight: 400;">
    <el-tab-pane label="数字管理平台" name="first"><Chuchuang></Chuchuang></el-tab-pane>
    <el-tab-pane label="企业成长期" name="second"><ChengZhang></ChengZhang></el-tab-pane>
    <el-tab-pane label="企业成熟" name="third"><ChengShuo></ChengShuo></el-tab-pane>
    <el-tab-pane label="增值服务" name="fourth"><Zen></Zen></el-tab-pane>
  </el-tabs>
  <footer class="footer">
    <button class="but">了解更多</button>
  </footer>


  <!--产品应用-->
  <div class="chanpin">
  <div class="hs">
  <h2>产品应用</h2>
  </div>
  <el-tabs v-model="activeName1" @tab-click="handleClick1" style="width: 1000px;">
    <el-tab-pane label="法律体检" name="first"><ChanPing1></ChanPing1></el-tab-pane>
    <el-tab-pane label="法律治疗" name="second"><ChanPing2></ChanPing2></el-tab-pane>
    <el-tab-pane label="法律保障" name="third"><ChanPing3></ChanPing3></el-tab-pane>
    <el-tab-pane label="云端电子合同" name="fourth"><ChanPing4></ChanPing4></el-tab-pane>
  </el-tabs>
  <!-- <div class="nav-buttons">
  <button class="button" :class="{ active: isActive('/startup') }">
    <router-link to="/startup">数字合作平台</router-link>
  </button>
  <button class="button" :class="{ active: isActive('/assistant') }">
    <router-link to="/assistant">科技e盟</router-link>
  </button>
  <button class="button" :class="{ active: isActive('/home/products/assistant') }">
    <router-link to="/home/products/assistant">云端企业助手</router-link>
  </button>
  <button class="button" :class="{ active: isActive('/home/products/platformdigital') }">
    <router-link to="/home/products/platformdigital">云端电子合作平台</router-link>
  </button>
</div> -->

  
  <router-view ></router-view>
  <footer class="footer">
    <button class="but">了解更多</button>
  </footer>
</div>
 <!--关于我们-->
  <header id="myHeader1"></header>

 <!--新闻中心-->
  <div class="hs2"> 
  <h2>新闻中心</h2>
</div>
  <section class="content-section">
    <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen.png" style="width: 950px; " />
  </section>

  <footer class="footer" style="margin-top:-300px">
    <button class="but" >了解更多</button>
  </footer>

  <header id="myHeader2"></header>

  <header id="myHeader3"></header>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Chuchuang from '@/components/StartupSolutions.vue'
import ChengZhang from '@/components/StartupSolutions22.vue'
import ChengShuo from '@/components/StartupSolutions33.vue'
import Zen from '@/components/StartupSolutions44.vue'
import ChanPing1 from '@/components/Chan_11.vue'
import ChanPing2 from '@/components/Chan_22.vue'
import ChanPing3 from '@/components/Chan_33.vue'
import ChanPing4 from '@/components/Chan_44.vue'




export default {
  name: 'Home',
  components: {
    HelloWorld,
    Chuchuang,
    ChengZhang,
    ChengShuo,
    Zen,
    ChanPing1,
    ChanPing2,
    ChanPing3,
    ChanPing4
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    handleClick(tab, event) {
        console.log(tab, event);
      
      },
      handleClick1(tab, event) {
        console.log(tab, event);
      
      }
    },

    data() {
      return {
        activeName: 'first',
        activeName1: 'first'

      };
    },
   

}


</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  #myHeader {
    /* 设置header的宽度和高度，或者让它自动适应 */
    width: 100%; /* 宽度占满整个容器 */
    height: 660px; /* 设置固定高度，或者根据需要调整 */
  
    /* 将图片设置为背景 */
    background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/shouye-shou.png" );
    
  
    /* 确保背景图片覆盖整个header */
    background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
    background-position: center; /* 图片居中显示 */
    background-repeat: no-repeat; /* 图片不重复 */
  
    /* 可选：如果需要，设置其他样式如边框、边距等 */
    border: none;
    margin: 0;
    padding: 0;
  }

  .el-tabs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 240px;
    margin-top: 30px
  }
  
  .but {
    margin-top: 10px;
    padding: 10px 20px;
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff; /* 文本颜色，可根据需要调整 */
    border: none;
    border-radius: 4px; /* 圆角大小，可根据需要调整 */
    background-image: linear-gradient(
      to right,
      #8bc1fc,
      #298bf3
    ); /* 渐变效果，从左到右，颜色从浅蓝到深蓝 */
    cursor: pointer; /* 鼠标悬停时变为手型 */
    transition: background 0.3s ease; /* 背景渐变过渡效果 */
    margin-bottom: 50px;
  }
  .but:hover {
    background-image: linear-gradient(
      to right,
      #0056b3,
      #007bff
    ); /* 鼠标悬停时的渐变效果 */

  }
  
  .but:active {
    background-image: linear-gradient(
      to right,
      #003a7f,
      #004a8c
    ); /* 点击时的渐变效果 */
  }
  
  .statistics {
    display: flex;
    justify-content: space-around;
    margin-top: -80px;
  }
  
  .statistic {
    text-align: center;
  }
  .hs{
    display: flex;  
          justify-content: center;  
          align-items: center;  
          font-family: Arial, sans-serif; /* 设置字体 */  
  }
  h2 {
    position: relative;
    display: inline-block;
    text-align: center; /* 文本居中 */
  }
  
  h2::after {
    content: "";  
          position: absolute;  
          bottom: -20px; /* 下滑线与文字之间的距离 */  
          left: 0;  
          right: 0;  
          border-bottom: 3px solid cornflowerblue; /* 自定义下滑线颜色和宽度 */  
  }
 
  .content-section {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
  }
  
  .footer {
    text-align: center;
    padding: 10px 0;
  }
  .header {
    text-align: center;
    padding: 20px;
  }
  .title {
    font-size: 24px;
  }
  .underline {
    border-bottom: 2px solid  #007bff;
    display: inline-block;
  }
  .chanpin{
    background-color: #F7F9FD;
    height: 600px;
    
    padding-top: 50px;
    
  }
  .nav-buttons {
    display: flex;
    margin-top: 50px;
    margin-left: 330px;
    text-align: center;
    width: 800px;
    height: 42px;
    border: 1px solid cornflowerblue;
    border-radius:50px; 
  
  }
  
    
.button {
    border: none;
    color: white;
     width: 200px;
     height: 42px;
    cursor: pointer;
    border-radius: 20px;
    justify-content: space-between;
    text-decoration: none; /* 移除下划线 */  
  }
  .button a {  
  color: black;  
  height: 42px;
  text-decoration: none; /* 移除下划线 */  
  border: none; /* 无边框 */  
  transition: color 0.3s ease, border-bottom 0.3s ease; /* 添加过渡效果 */  
}  

/* 鼠标悬停样式 */  
.button a:hover {  
  color: rgb(97, 150, 249); /* 悬停时文字颜色变为蓝色 */  
  border-bottom: 2px solid rgb(97, 150, 249); /* 添加底部蓝色边框 */  
  margin-bottom: 15px;
  padding-bottom: 15px;
}  
  
/* 点击时样式 */  
.button.active  {
  
  background-color:  rgb(97, 150, 249);
 
}
.button.active a {
  color: white;
  margin-top: 20px;
  /* margin-bottom: 5px;
  padding-bottom: 15px; */
}

  #myHeader1 {
    /* 设置header的宽度和高度，或者让它自动适应 */
    width: 100%; /* 宽度占满整个容器 */
    height: 620px; /* 设置固定高度，或者根据需要调整 */
  
    /* 将图片设置为背景 */
    background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/shou-womwn.png");  
    /* 确保背景图片覆盖整个header */
    background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
    background-position: center; /* 图片居中显示 */
    background-repeat: no-repeat; /* 图片不重复 */
  
    /* 可选：如果需要，设置其他样式如边框、边距等 */
    border: none;
    margin: 0;
    padding: 0;
  }
  .hs2{
    display: flex;  
          justify-content: center;  
          align-items: center;  
          margin-top: 50px;
          font-family: Arial, sans-serif; /* 设置字体 */  
  }
  #myHeader2{
    /* 设置header的宽度和高度，或者让它自动适应 */
    width: 100%; /* 宽度占满整个容器 */
    height:750px; /* 设置固定高度，或者根据需要调整 */
  
    /* 将图片设置为背景 */
    background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/hezuo.png");
  
    /* 确保背景图片覆盖整个header */
    background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
    background-position: center; /* 图片居中显示 */
    background-repeat: no-repeat; /* 图片不重复 */
  
    /* 可选：如果需要，设置其他样式如边框、边距等 */
    border: none;
    margin: 0;
    padding: 0;
  }
  #myHeader3{
    /* 设置header的宽度和高度，或者让它自动适应 */
    width: 100%; /* 宽度占满整个容器 */
    height:660px; /* 设置固定高度，或者根据需要调整 */
  
    /* 将图片设置为背景 */
    background-image: url("https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/weiye.png");
  
    /* 确保背景图片覆盖整个header */
    background-size: cover; /* 图片将被缩放以完全覆盖内容区域 */
    background-position: center; /* 图片居中显示 */
    background-repeat: no-repeat; /* 图片不重复 */
  
    /* 可选：如果需要，设置其他样式如边框、边距等 */
    border: none;
    margin: 0;
    padding: 0;
  }
  </style>
  
