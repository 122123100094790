<template>
    <div class="about">
      <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/shou-chengzhang.png" style="width:920px ; margin-left: -50x; margin-top: 30px;" />
    </div>
  </template>

  <script>
  export default {
    
  }
  </script>
  <style scoped>
  
  </style>
  