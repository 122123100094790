<template>
  <div class="about">
    <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/tijian.png" style="width:820px ; margin-left: -50x; margin-top: 30px;" />
  </div>
</template>

<script>
export default {
  
}
</script>
<style scoped>

</style>
