<template>
    <div class="outer-container">  
  <div class="ccer-contract">
    <h1>CCER交易买卖合同</h1>
   

    <div class="contract-content">
        <p>合同编号：____</p>
      <p>买方（以下简称甲方）：_________________</p>
      <p>卖方（以下简称乙方）：_________________</p>

      <p>
        <strong>第一条 标的物</strong>
      </p>
      <p>乙方自愿将其产生的核证自愿减排量（CCER）转让给甲方，转让数量为____吨二氧化碳当量。</p>

      <p>
        <strong>第二条 交易价格</strong>
      </p>
      <p>经双方协商，转让价格确定为每吨二氧化碳当量人民币____元，总价为人民币____元。</p>

      <p>
        <strong>第三条 交易方式和资金支付</strong>
      </p>
      <p>甲方采用____（一次性付款/分期付款）方式支付转让价款。甲方于本合同签订后____日内，将转让价款全部支付到乙方指定账户。</p>

      <p>
        <strong>第四条 交付与验收</strong>
      </p>
      <p>乙方应在收到甲方支付的转让价款后____日内，将标的物（CCER）交付给甲方。甲方应在收到标的物后____日内完成验收，并签署验收确认书。</p>

      <p>
        <strong>第五条 所有权与风险转移</strong>
      </p>
      <p>标的物的所有权自甲方支付全部转让价款并签署验收确认书之日起转移给甲方，标的物毁损、灭失的风险亦同时转移。</p>

      <p>
        <strong>第六条 保密条款</strong>
      </p>
      <p>双方应对本合同的内容及履行过程中知悉的对方商业秘密和敏感信息予以保密，未经对方书面同意，不得向任何第三方泄露或用于本合同以外的其他目的。</p>

      <p>
        <strong>第七条 违约责任</strong>
      </p>
      <p>1. 若甲方未按照约定支付转让价款的，每逾期一日，应按照逾期支付金额的____%向乙方支付违约金。</p>
      <p>2. 若乙方未按照约定交付标的物的，每逾期一日，应按照合同总价款的____%向甲方支付违约金，并赔偿因此给甲方造成的损失。</p>

      <p>
        <strong>第八条 不可抗力</strong>
      </p>
      <p>因不可抗力因素导致本合同无法履行的，双方应及时通知对方，并协商解决方案。</p>

      <p>
        <strong>第九条 争议解决</strong>
      </p>
      <p>因本合同引起的或与本合同有关的任何争议，双方应首先尝试友好协商解决。协商不成的，任何一方均有权向____（合同签订地/被告住所地）人民法院提起诉讼。</p>

      <p>
        <strong>第十条 通知与送达</strong>
      </p>
      <p>双方应确保本合同中填写的联系方式准确无误，如有变更，应及时通知对方。双方之间的任何通知和文件，以书面方式送达至对方在本合同中填写的地址即视为送达。</p>

      <p>
        <strong>第十一条 合同生效与变更</strong>
      </p>
      <p>本合同自双方签字（或盖章）之日起生效。本合同一式两份，甲乙双方各执一份。本合同未尽事宜，可由双方协商补充。经双方协商一致达成的补充协议，与本合同具有同等法律效力。</p>

      <p>
        甲方（盖章）：
        <br />法定代表人（签字）：
        <br />签订日期：____年__月__日
      </p>

      <p>
        乙方（盖章）：
        <br />法定代表人（签字）：
        <br />签订日期：____年__月__日
      </p>

      <p>此合同模板仅供参考，具体内容、条款及百分比等数据，需要根据实际情况确定。在签署正式合同前，建议咨询法律专业人士，确保合同的有效性和可执行性。</p>
    </div>
    <div class="download-section">
        <a href="URL_TO_YOUR_WORD_FILE" download="CCER交易买卖合同.docx" class="download-button">下载合同模板</a>
      </div>
  </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.outer-container{
    background-color: rgb(225, 225, 225); /* 设置为红色背景 */  
        padding: 130px; /* 控制内部div与红色背景的距离 */  
}
.ccer-contract {
    background-color: white; /* 显式设置为白色，但通常默认就是白色 */  
        /* 其他样式，如宽度、高度等 */  
        width: 100%;  
        height: 100%; /* 如果需要的话 */  
        box-sizing: border-box; /* 确保padding不会影响宽度 */  
 
 

}
h1,
h2 {
  text-align: center;
}
p {
  margin-bottom: 1em;
}
.contract-content {
  margin: 0 auto;
  width: 80%;
}
.contract-content p {
  text-indent: 2em; /* 首行缩进 */
}
.download-section {
  text-align: center; /* 让下载按钮居中显示 */
  margin-top: 20px; /* 在下载部分和其它内容之间增加一些间距 */
}

.download-button {
  display: inline-block;
  background-color: #007bff; /* 蓝色背景 */
  color: white; /* 白色文字 */
  padding: 10px 20px; /* 内边距 */
  text-decoration: none; /* 去除下划线 */
  border-radius: 5px; /* 轻微的圆角 */
  font-size: 16px; /* 文字大小 */
  cursor: pointer; /* 鼠标悬停时显示手形图标 */
  transition: background-color 0.3s; /* 为背景色变化添加过渡效果 */
}

.download-button:hover {
  background-color: #0056b3; /* 鼠标悬停时深蓝色背景 */
}
</style>