var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('header',{attrs:{"id":"myHeader"}}),_vm._m(0),_vm._m(1),_c('el-tabs',{staticStyle:{"width":"1000px","font-weight":"400"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"数字管理平台","name":"first"}},[_c('Chuchuang')],1),_c('el-tab-pane',{attrs:{"label":"企业成长期","name":"second"}},[_c('ChengZhang')],1),_c('el-tab-pane',{attrs:{"label":"企业成熟","name":"third"}},[_c('ChengShuo')],1),_c('el-tab-pane',{attrs:{"label":"增值服务","name":"fourth"}},[_c('Zen')],1)],1),_vm._m(2),_c('div',{staticClass:"chanpin"},[_vm._m(3),_c('el-tabs',{staticStyle:{"width":"1000px"},on:{"tab-click":_vm.handleClick1},model:{value:(_vm.activeName1),callback:function ($$v) {_vm.activeName1=$$v},expression:"activeName1"}},[_c('el-tab-pane',{attrs:{"label":"法律体检","name":"first"}},[_c('ChanPing1')],1),_c('el-tab-pane',{attrs:{"label":"法律治疗","name":"second"}},[_c('ChanPing2')],1),_c('el-tab-pane',{attrs:{"label":"法律保障","name":"third"}},[_c('ChanPing3')],1),_c('el-tab-pane',{attrs:{"label":"云端电子合同","name":"fourth"}},[_c('ChanPing4')],1)],1),_c('router-view'),_vm._m(4)],1),_c('header',{attrs:{"id":"myHeader1"}}),_vm._m(5),_vm._m(6),_vm._m(7),_c('header',{attrs:{"id":"myHeader2"}}),_c('header',{attrs:{"id":"myHeader3"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"statistics"},[_c('img',{staticStyle:{"width":"1000px"},attrs:{"src":"https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/shouye-diejia.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hs"},[_c('h2',[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('button',{staticClass:"but"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hs"},[_c('h2',[_vm._v("产品应用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('button',{staticClass:"but"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hs2"},[_c('h2',[_vm._v("新闻中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-section"},[_c('img',{staticStyle:{"width":"950px"},attrs:{"src":"https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/xinwen.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer",staticStyle:{"margin-top":"-300px"}},[_c('button',{staticClass:"but"},[_vm._v("了解更多")])])
}]

export { render, staticRenderFns }