import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DoctorDetailc from '../use-components/maimai.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    children:[
      {
        path:'',
        component: () => import('../components/About_11.vue')
      },
      {
        path:'22',
         component: () => import('../components/About_22.vue')
      },
      {
        path:'33',
         component: () => import('../components/About_33.vue')
      },
      {
        path:'44',
         component: () => import('../components/About_44.vue')
      }
    ]
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
  },
  {
    path: '/news',
    name: 'News',
    
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  {
    path: '/we',
    name: 'We',
    
    component: () => import(/* webpackChunkName: "news" */ '../views/We.vue'),
  },
  {
    path: '/qudao',
    name: 'qudao',
    
    component: () => import(/* webpackChunkName: "news" */ '../views/qudao.vue'),
  },
  {
    path: '/leibie',
    name: 'leibie',
    
    component: () => import(/* webpackChunkName: "news" */ '../use/leibie.vue'),
  },
  {  
    path: '/doctor/:id', // 使用动态路由参数来获取doctor的id  
    name: 'doctor-detail',  
    component: () => import(/* webpackChunkName: "news" */ '../use-components/DoctorDetail.vue'),
  },  
  {
    path: '/shencha',
    name: 'shencha',
    
    component: () => import(/* webpackChunkName: "news" */ '../use/shencha.vue'),
  },
  {
    path: '/shenchademo',
    name: 'shenchademo',
    
    component: () => import(/* webpackChunkName: "news" */ '../use/shenchademo.vue'),
  },
  {
    path: '/new11',
    name: 'new11',
    
    component: () => import(/* webpackChunkName: "news" */ '../new-components/new11.vue'),
  },
  {
    path: '/new22',
    name: 'new22',
    
    component: () => import(/* webpackChunkName: "news" */ '../new-components/new22.vue'),
  },
  {
    path: '/new33',
    name: 'new33',
    
    component: () => import(/* webpackChunkName: "news" */ '../new-components/new33.vue'),
  },
  {
    path: '/new44',
    name: 'new44',
    
    component: () => import(/* webpackChunkName: "news" */ '../new-components/new44.vue'),
  },
  {
    path: '/kefu',
    name: 'kefu',
    
    component: () => import(/* webpackChunkName: "news" */ '../use/kefu.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',  
  routes
})

export default router
