<template>
  <div class="fixed-icon">
    <i class="el-icon-edit"></i>
  </div>
</template>  
    
  <script>
export default {
  name: "FixedIcon"
  // ... 其他选项
};
</script>  
    
  <style scoped>
.fixed-icon {
  position: fixed;
  right: 20px; /* 根据需要调整距离右侧的距离 */
  bottom: 20px; /* 根据需要调整距离底部的距离 */
  width: 100px; /* 根据你的图标或内容调整大小 */
  height: 100px; /* 根据你的图标或内容调整大小 */
  background-color: #f00; /* 示例背景色，你可以根据需要设置 */
  border-radius: 50%; /* 圆形图标 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* 添加鼠标悬停效果 */
  z-index: 999; /* 确保图标在其他内容之上 */
  /* 其他样式，如阴影、动画等 */
}
</style>