<template>
  <div id="app">
    <nav>
      <ul>
        <li class="nav-item" >
          <router-link to="/" active-class="active" exact>首页</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about" active-class="active">专业服务</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/product" active-class="active">产品应用</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/news" active-class="active">新闻中心</router-link>
        </li>
        
        <li class="nav-item">
          <router-link to="/qudao" active-class="active">渠道合作</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/we" active-class="active">关于我们</router-link>
        </li>
        
      </ul>
      <div class="contact">
        <img src="https://fei-13559909623-1325772027.cos.ap-guangzhou.myqcloud.com/shouye-logo.png" style="width:180px" />
      </div>
    </nav>
    <div class="fixed-icon" @click="handleClick">
    <i class="el-icon-headset"></i>
  </div>
    <router-view/>
  </div>
</template>

<script>
import FixedIcon from './use-components/kefu-daohang.vue';  
  
  export default {  
    components: {  
      FixedIcon  
    },
    methods: {  
    handleClick() {  
      // 处理点击事件，例如显示一个提示或进行其他操作  
      this.$router.push({ name: "kefu" });
    }  
  }  
  }

</script>

<style>
 *{
  margin: 0;
  padding: 0;
}
nav {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding-top: 30px;
  height: 39px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 80px;
  margin-left: 180px;
  font-size: 15px;
  font-weight: 550; /* 使用关键字 "bold" 来加粗文本 */
}

nav ul li {
  cursor: pointer;
}

.contact {
  /* align-self: center; */
  padding-right: 70px;
}
/* 默认样式 */  
.nav-item a {  
  color: black;  
  height: 100px;
  text-decoration: none; /* 移除下划线 */  
  border: none; /* 无边框 */  
  transition: color 0.3s ease, border-bottom 0.3s ease; /* 添加过渡效果 */  
}  
  
/* 鼠标悬停样式 */  
.nav-item a:hover {  
  color: rgb(97, 150, 249); /* 悬停时文字颜色变为蓝色 */  
  border-bottom: 2px solid rgb(97, 150, 249); /* 添加底部蓝色边框 */  
  margin-bottom: 15px;
  padding-bottom: 15px;
}  
  
/* 点击时样式 */  
.nav-item a.active {  
  color: rgb(97, 150, 249); /* 悬停时文字颜色变为蓝色 */  
  border-bottom: 2px solid rgb(97, 150, 249); /* 添加底部蓝色边框 */  
  margin-bottom: 15px;
  padding-bottom: 15px;
}  
.fixed-icon {
  position: fixed;
  right: 10px; /* 根据需要调整距离右侧的距离 */
  bottom: 300px; /* 根据需要调整距离底部的距离 */
  width: 66px; /* 根据你的图标或内容调整大小 */
  height: 66px; /* 根据你的图标或内容调整大小 */
  background-color: rgb(226, 240, 255); /* 示例背景色，你可以根据需要设置 */
  border-radius: 50%; /* 圆形图标 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* 添加鼠标悬停效果 */
  z-index: 999; /* 确保图标在其他内容之上 */
  /* 其他样式，如阴影、动画等 */
  transition: transform 0.3s ease;  
}
.fixed-icon:hover {  
  /* hover 效果，例如放大或改变颜色 */  
  transform: scale(1.1);  
  background-color: #f60;  
}  
</style>
